import moment from 'moment'
import { useChartColors } from '@/theme/charts/ChartColors.js'

export function useMetricsData() {
  const { gradients } = useChartColors()

  /**
   * Given an array of unix timestamps (times) and a time period,
   * Converts the timestamps to moment.js formatted strings.
   * For formatting options, see: https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
   * @param {string[]} times
   * @param {string} period
   * @returns {string[]}
   */
  function getLabels(times, period) {
    switch (period) {
      case 'year':
        return times.map((time) => moment.unix(time).format('MMM YYYY'))
      case 'month':
        return times.map((time) => moment.unix(time).format('MMM D'))
      case 'week':
        return times.map((time) => moment.unix(time).format('MMM D'))
    }
  }

  const defaultLineChartDataset = {
    pointBorderColor: '#f9f9f9',
    pointRadius: 5,
    borderWidth: 2,
    tension: 0.3
  }

  const defaultBarChartDataset = {
    borderColor: '#f9f9f9',
    borderWidth: 2,
    borderRadius: 4 // Change this value to adjust the radius
  }

  const defaultFontOptions = {
    font: {
      size: 11, // Font size
      family: 'Cera Pro', // Font family
      weight: '500', // Font weight
      lineHeight: 1.5 // Line height
    },
    color: 'rgba(28, 26, 27, 0.5)', // Font color
    padding: 10 // Padding around the text
  }

  const defaultAxisOptions = {
    ticks: {
      // Style settings for the x-axis
      ...defaultFontOptions
    },
    border: {
      display: false
    },
    grid: {
      display: false
    }
  }
  /**
   * Charts that give a general overview of a company's performance.
   * Should not contain sensitive information.
   * @param {Object} metrics
   * @param {ref} timePeriod
   * @returns {Object}
   */
  function getOverviewCharts(metrics, timePeriod) {
    try {
      const charts = [
        {
          title: 'Overview',
          description: 'Key performance indicators for this company',
          visible: true,
          size: 4,
          type: 'card',
          cards: [
            {
              title: 'New leads',
              icon: 'house-flag',
              type: '',
              route: 'Leads List',
              timePeriod: timePeriod.value,
              description:
                'The total amount of new leads and clients added by this company over this time period.',
              currentAmount: metrics.new_clients_count,
              previousAmount: metrics.new_clients_previous_count,
              biggerIsBetter: true
            },
            {
              title: 'Estimates sent',
              icon: 'mailbox-flag-up',
              type: '',
              route: 'Estimates List',
              timePeriod: timePeriod.value,
              description:
                'The total amount of unique estimates sent to clients, not including individual change orders.',
              currentAmount: metrics.quotes_sent_count,
              previousAmount: metrics.quotes_sent_previous_count,
              biggerIsBetter: true
            },
            {
              title: 'Projects booked',
              icon: 'house-building',
              type: '',
              route: 'Projects List',
              timePeriod: timePeriod.value,
              description:
                'The total amount of estimates converted to booked projects. Includes pending, in-progress, and completed projects.',
              currentAmount: metrics.quotes_booked_count,
              previousAmount: metrics.quotes_booked_previous_count,
              biggerIsBetter: true
            },
            {
              title: 'Projects completed',
              icon: 'badge-check',
              type: '',
              route: 'Projects List',
              timePeriod: timePeriod.value,
              description:
                'The total amount of booked projects that are either in-progress or completed.',
              currentAmount: metrics.quotes_worked_count,
              previousAmount: metrics.quotes_worked_previous_count,
              biggerIsBetter: true
            }
          ]
        },
        {
          title: 'Estimates sent vs projects booked',
          description:
            'A comparison between the amount of estimates sent and the amount of estimates converted to booked projects.',
          visible: true,
          size: 2,
          type: 'line',
          options: {
            scales: {
              y: {
                ...defaultAxisOptions,
                border: {
                  display: false
                },
                beginAtZero: true
              },
              x: {
                ...defaultAxisOptions
              }
            },
            plugins: {
              legend: {
                labels: {
                  ...defaultFontOptions,
                  usePointStyle: true,
                  boxWidth: 16,
                  boxHeight: 16
                }
              }
            }
          },
          data: {
            labels: getLabels(Object.keys(metrics.quotes_sent), timePeriod.value),
            datasets: [
              {
                ...defaultLineChartDataset,
                label: 'Booked',
                data: Object.values(metrics.quotes_booked),
                pointBackgroundColor: gradients.blue[0],
                borderColor: gradients.blue[4],
                backgroundColor: gradients.blue_transparent[5],
                fill: true
              },
              {
                ...defaultLineChartDataset,
                label: 'Sent',
                data: Object.values(metrics.quotes_sent),
                pointBackgroundColor: gradients.grey[0],
                borderColor: gradients.grey[3]
              }
            ]
          }
        },
        {
          title: 'Lead generation leaderboard',
          description: 'The number of new leads and clients grouped by their creator.',
          visible: false,
          size: 2,
          type: 'bar',
          options: {
            indexAxis: 'y',
            scales: {
              y: {
                ...defaultAxisOptions,
                beginAtZero: true
              },
              x: {
                ...defaultAxisOptions
              }
            },
            plugins: {
              legend: {
                display: false
              }
            }
          },
          data: {
            labels: Object.keys(metrics.new_clients_by_owner),
            datasets: [
              {
                ...defaultBarChartDataset,
                data: Object.values(metrics.new_clients_by_owner),
                backgroundColor: gradients.blue
              }
            ]
          }
        },
        {
          title: 'Booked project leaderboard',
          description: 'The number of booked projects grouped by their project manager.',
          visible: true,
          size: 2,
          type: 'bar',
          options: {
            indexAxis: 'y',
            scales: {
              y: {
                ...defaultAxisOptions,
                beginAtZero: true
              },
              x: {
                ...defaultAxisOptions
              }
            },
            plugins: {
              legend: {
                display: false
              }
            }
          },
          data: {
            labels: Object.keys(metrics.quotes_booked_by_project_manager),
            datasets: [
              {
                ...defaultBarChartDataset,
                data: Object.values(metrics.quotes_booked_by_project_manager),
                backgroundColor: gradients.blue
              }
            ]
          }
        },
        {
          title: 'Project statuses',
          description:
            'All booked projects that have yet to be completed, grouped by their status.',
          visible: true,
          size: 1,
          type: 'doughnut',
          options: {
            cutout: 80,
            plugins: {
              legend: {
                labels: {
                  ...defaultFontOptions,
                  usePointStyle: true,
                  boxWidth: 16,
                  boxHeight: 16
                }
              }
            }
          },
          data: {
            labels: Object.keys(metrics.quotes_by_status),
            datasets: [
              {
                data: Object.values(metrics.quotes_by_status),
                backgroundColor: gradients.blue.filter((_, i) => i % 2 === 0),
                borderColor: ['#f9f9f9'],
                borderWidth: 2
              }
            ]
          }
        },
        {
          title: 'New items & assemblies',
          description: 'The number of new items & assemblies added to this company catalog.',
          visible: true,
          size: 2,
          type: 'line',
          options: {
            scales: {
              y: {
                ...defaultAxisOptions,
                beginAtZero: true
              },
              x: {
                ...defaultAxisOptions
              }
            },
            plugins: {
              legend: {
                display: false
              }
            }
          },
          data: {
            labels: getLabels(Object.keys(metrics.cost_types_created), timePeriod.value),
            datasets: [
              {
                ...defaultLineChartDataset,
                label: 'New items & assemblies',
                data: Object.values(metrics.cost_types_created),
                pointBackgroundColor: gradients.blue[0],
                borderColor: gradients.blue[3],
                backgroundColor: gradients.blue_transparent[5],
                fill: true
              }
            ]
          }
        },
        {
          title: 'Lead conversions',
          description: 'The proportion of new leads converted to clients.',
          visible: true,
          size: 1,
          type: 'doughnut',
          options: {
            cutout: 80,
            plugins: {
              legend: {
                labels: {
                  ...defaultFontOptions,
                  usePointStyle: true,
                  boxWidth: 16,
                  boxHeight: 16
                }
              }
            }
          },
          data: {
            labels: Object.keys(metrics.clients_by_status),
            datasets: [
              {
                data: Object.values(metrics.clients_by_status),
                backgroundColor: gradients.blue.filter((_, i) => i % 5 === 0),
                borderColor: ['#f9f9f9'],
                borderWidth: 2
              }
            ]
          }
        }
      ]
      return charts
    } catch (e) {
      console.error(e)
      return []
    }
  }

  /**
   * Charts providing insights into a company's finances.
   * !! Can contain sensitive information !!
   * @param {Object} metrics
   * @param {ref} timePeriod
   * @returns {Object}
   */
  function getFinanceCharts(metrics, timePeriod) {
    try {
      const charts = [
        {
          title: 'Finances',
          description: "Key performance indicators for this company's finances",
          visible: true,
          size: 4,
          type: 'card',
          cards: [
            {
              title: 'Value estimated',
              icon: 'money-check-dollar-pen',
              type: 'currency',
              route: 'Estimates List',
              timePeriod: timePeriod.value,
              description:
                'The total value of estimates sent over this time period. Does not include taxes, making this the net total.',
              currentAmount: metrics.quotes_sent_price_net_sum,
              previousAmount: metrics.quotes_sent_price_net_previous_sum,
              biggerIsBetter: true
            },
            {
              title: 'Value booked',
              icon: 'comments-dollar',
              type: 'currency',
              route: 'Projects List',
              timePeriod: timePeriod.value,
              description:
                'The total value of projects booked over this time period. Does not include taxes, making this the net total.',
              currentAmount: metrics.quotes_booked_price_net_sum,
              previousAmount: metrics.quotes_booked_price_net_previous_sum,
              biggerIsBetter: true
            },
            {
              title: 'Average project value',
              icon: 'box-dollar',
              type: 'currency',
              route: 'Projects List',
              timePeriod: timePeriod.value,
              description:
                'The average value of projects booked within this time period. Does not include taxes, making this the net average.',
              currentAmount: metrics.quotes_booked_price_net_average,
              previousAmount: metrics.quotes_booked_price_net_previous_average,
              biggerIsBetter: true
            },
            {
              title: 'Average Profit',
              icon: 'circle-three-quarters-stroke',
              type: 'percent',
              route: 'Items List',
              timePeriod: timePeriod.value,
              description:
                'The average profit on items created within this time period. Includes estimates and booked projects.',
              currentAmount: c.markupToMargin(metrics.item_markup_average) * 100,
              previousAmount: c.markupToMargin(metrics.item_markup_previous_average) * 100,
              biggerIsBetter: true
            }
          ]
        },
        {
          title: 'Top booked projects',
          description: 'Your largest projects ranked by net price.',
          visible: true,
          size: 2,
          type: 'bar',
          options: {
            indexAxis: 'y',
            scales: {
              y: {
                ...defaultAxisOptions,
                beginAtZero: true
              },
              x: {
                ...defaultAxisOptions
              }
            },
            plugins: {
              legend: {
                display: false
              }
            }
          },
          data: {
            labels: Object.keys(metrics.top_10_quotes_by_price_net),
            datasets: [
              {
                ...defaultBarChartDataset,
                data: Object.values(metrics.top_10_quotes_by_price_net),
                backgroundColor: gradients.blue
              }
            ]
          }
        },
        {
          title: 'Profit vs booked project value',
          description:
            'A comparison between booked project net value and the proportion of which is profit.',
          visible: true,
          size: 2,
          type: 'line',
          options: {
            scales: {
              y: {
                ...defaultAxisOptions,
                beginAtZero: true
              },
              x: {
                ...defaultAxisOptions
              }
            },
            plugins: {
              legend: {
                labels: {
                  ...defaultFontOptions,
                  usePointStyle: true,
                  boxWidth: 16,
                  boxHeight: 16
                }
              }
            }
          },
          data: {
            labels: getLabels(Object.keys(metrics.quotes_booked_cost_net), timePeriod.value),
            datasets: [
              {
                ...defaultLineChartDataset,
                label: 'Profit',
                data: Object.values(metrics.quotes_booked_price_net).map(
                  (price, index) => price - Object.values(metrics.quotes_booked_cost_net)[index]
                ),
                pointBackgroundColor: gradients.blue[0],
                borderColor: gradients.blue[3],
                backgroundColor: gradients.blue_transparent[5],
                fill: true
              },
              {
                ...defaultLineChartDataset,
                label: 'Project value',
                data: Object.values(metrics.quotes_booked_price_net),
                pointBackgroundColor: gradients.grey[0],
                borderColor: gradients.grey[3]
              }
            ]
          }
        },
        {
          title: 'Net, tax, and gross booked project value',
          description:
            'The net value, tax amount, and gross (net + tax) value of all your booked projects added together.',
          visible: false,
          size: 2,
          type: 'line',
          options: {
            scales: {
              y: {
                ...defaultAxisOptions,
                beginAtZero: true
              },
              x: {
                ...defaultAxisOptions
              }
            },
            plugins: {
              legend: {
                labels: {
                  ...defaultFontOptions,
                  usePointStyle: true,
                  boxWidth: 16,
                  boxHeight: 16
                }
              }
            }
          },
          data: {
            labels: getLabels(Object.keys(metrics.quotes_booked_price_net), timePeriod.value),
            datasets: [
              {
                ...defaultLineChartDataset,
                label: 'Net',
                type: 'line',
                data: Object.values(metrics.quotes_booked_price_net),
                borderColor: gradients.blue[3],
                pointBackgroundColor: gradients.blue[0],
                backgroundColor: gradients.blue_transparent[5],
                fill: true
              },
              {
                ...defaultLineChartDataset,
                label: 'Gross',
                type: 'line',
                data: Object.values(metrics.quotes_booked_price_gross),
                pointBackgroundColor: gradients.purple[0],
                borderColor: gradients.purple[3]
              },
              {
                label: 'Tax',
                type: 'bar',
                ...defaultBarChartDataset,
                data: Object.values(metrics.quotes_booked_price_tax),
                backgroundColor: gradients.grey[0]
              }
            ]
          }
        },
        {
          title: 'Net, tax, and gross estimate value',
          description:
            'The net value, tax amount, and gross (net + tax) value of all your sent estimates added together.',
          visible: false,
          size: 2,
          type: 'line',
          options: {
            scales: {
              y: {
                ...defaultAxisOptions,
                beginAtZero: true
              },
              x: {
                ...defaultAxisOptions
              }
            },
            plugins: {
              legend: {
                labels: {
                  ...defaultFontOptions,
                  usePointStyle: true,
                  boxWidth: 16,
                  boxHeight: 16
                }
              }
            }
          },
          data: {
            labels: getLabels(Object.keys(metrics.quotes_sent_price_net), timePeriod.value),
            datasets: [
              {
                ...defaultLineChartDataset,
                label: 'Net',
                type: 'line',
                data: Object.values(metrics.quotes_sent_price_net),
                pointBackgroundColor: gradients.blue[0],
                borderColor: gradients.blue[3],
                backgroundColor: gradients.blue_transparent[5],
                fill: true
              },
              {
                ...defaultLineChartDataset,
                label: 'Gross',
                type: 'line',
                data: Object.values(metrics.quotes_sent_price_gross),
                pointBackgroundColor: gradients.purple[0],
                borderColor: gradients.purple[3]
              },
              {
                ...defaultBarChartDataset,
                label: 'Tax',
                type: 'bar',
                data: Object.values(metrics.quotes_sent_price_tax),
                backgroundColor: gradients.grey[0]
              }
            ]
          }
        },
        {
          title: 'Labor vs material costs',
          description: 'The proportion of your costs attributed to labor or materials.',
          visible: true,
          size: 1,
          type: 'doughnut',
          options: {
            cutout: 80,
            plugins: {
              legend: {
                labels: {
                  ...defaultFontOptions,
                  usePointStyle: true,
                  boxWidth: 16,
                  boxHeight: 16
                }
              }
            }
          },
          data: {
            labels: Object.keys(metrics.item_costs_by_type),
            datasets: [
              {
                data: Object.values(metrics.item_costs_by_type),
                backgroundColor: gradients.blue.filter((_, i) => i % 5 === 0),
                borderColor: ['#f9f9f9'],
                borderWidth: 2
              }
            ]
          }
        },
        {
          title: 'Booked project value leaderboard',
          description: 'The sum of all booked project values grouped by project manager.',
          visible: true,
          size: 2,
          type: 'bar',
          options: {
            indexAxis: 'y',
            scales: {
              y: {
                ...defaultAxisOptions,
                beginAtZero: true
              },
              x: {
                ...defaultAxisOptions
              }
            },
            plugins: {
              legend: {
                display: false
              }
            }
          },
          data: {
            labels: Object.keys(metrics.quotes_booked_net_price_sum_by_project_manager),
            datasets: [
              {
                ...defaultBarChartDataset,
                data: Object.values(metrics.quotes_booked_net_price_sum_by_project_manager),
                backgroundColor: gradients.blue
              }
            ]
          }
        },
        {
          title: 'Invoice statuses',
          description: 'Invoices grouped by their status.',
          visible: true,
          size: 1,
          type: 'doughnut',
          options: {
            cutout: 80,
            plugins: {
              legend: {
                labels: {
                  ...defaultFontOptions,
                  usePointStyle: true,
                  boxWidth: 16,
                  boxHeight: 16
                }
              }
            }
          },
          data: {
            labels: Object.keys(metrics.invoices_by_status),
            datasets: [
              {
                data: Object.values(metrics.invoices_by_status),
                backgroundColor: gradients.blue.filter((_, i) => i % 3 === 0),
                borderColor: ['#f9f9f9'],
                borderWidth: 2
              }
            ]
          }
        },
        {
          title: 'Estimates sent vs projects booked net value',
          description:
            'A comparison between the net value of estimates sent and the net value of estimates converted to booked projects.',
          visible: false,
          size: 2,
          type: 'line',
          options: {
            scales: {
              y: {
                ...defaultAxisOptions,
                beginAtZero: true
              },
              x: {
                ...defaultAxisOptions
              }
            },
            plugins: {
              legend: {
                labels: {
                  ...defaultFontOptions,
                  usePointStyle: true,
                  boxWidth: 16,
                  boxHeight: 16
                }
              }
            }
          },
          data: {
            labels: getLabels(Object.keys(metrics.quotes_sent_price_net), timePeriod.value),
            datasets: [
              {
                ...defaultLineChartDataset,
                label: 'Value booked',
                data: Object.values(metrics.quotes_booked_price_net),
                pointBackgroundColor: gradients.blue[0],
                borderColor: gradients.blue[4],
                backgroundColor: gradients.blue_transparent[5],
                fill: true
              },
              {
                ...defaultLineChartDataset,
                label: 'Value estimated',
                data: Object.values(metrics.quotes_sent_price_net),
                pointBackgroundColor: gradients.grey[0],
                borderColor: gradients.grey[3]
              }
            ]
          }
        },
        {
          title: 'Estimates sent vs projects booked gross value',
          description:
            'A comparison between the gross value of estimates sent and the gross value of estimates converted to booked projects.',
          visible: false,
          size: 2,
          type: 'line',
          options: {
            scales: {
              y: {
                ...defaultAxisOptions,
                beginAtZero: true
              },
              x: {
                ...defaultAxisOptions
              }
            },
            plugins: {
              legend: {
                labels: {
                  ...defaultFontOptions,
                  usePointStyle: true,
                  boxWidth: 16,
                  boxHeight: 16
                }
              }
            }
          },
          data: {
            labels: getLabels(Object.keys(metrics.quotes_sent_price_gross), timePeriod.value),
            datasets: [
              {
                ...defaultLineChartDataset,
                label: 'Value booked',
                data: Object.values(metrics.quotes_booked_price_gross),
                pointBackgroundColor: gradients.blue[0],
                borderColor: gradients.blue[4],
                backgroundColor: gradients.blue_transparent[5],
                fill: true
              },
              {
                ...defaultLineChartDataset,
                label: 'Value estimated',
                data: Object.values(metrics.quotes_sent_price_gross),
                pointBackgroundColor: gradients.grey[0],
                borderColor: gradients.grey[3]
              }
            ]
          }
        }
      ]
      return charts
    } catch (e) {
      console.error(e)
      return []
    }
  }

  /**
   * Charts that compare or aggregate data from multiple franchisees.
   * !! Can contain sensitive information !!
   * @param {Object} metrics
   * @param {ref} timePeriod
   * @returns {Object}
   */
  function getFranchiseCharts(metrics, timePeriod) {
    try {
      const franchiseeCount = Object.keys(metrics?.new_clients_by_company || [])?.length || 0

      const charts = [
        {
          title: 'Overview',
          description: 'Key performance indicators aggregated from the selected companies.',
          visible: true,
          size: 4,
          type: 'card',
          cards: [
            {
              title: 'New leads',
              icon: 'user-plus',
              type: '',
              route: '',
              timePeriod: timePeriod.value,
              description:
                'The total amount of new leads and clients the selected companies added over this time period.',
              currentAmount: metrics.new_clients_count,
              previousAmount: metrics.new_clients_previous_count,
              biggerIsBetter: true
            },
            {
              title: 'Estimates sent',
              icon: 'mailbox-flag-up',
              type: '',
              route: '',
              timePeriod: timePeriod.value,
              description:
                'The total amount of unique estimates the selected companies sent to clients, not including individual change orders.',
              currentAmount: metrics.quotes_sent_count,
              previousAmount: metrics.quotes_sent_previous_count,
              biggerIsBetter: true
            },
            {
              title: 'Projects booked',
              icon: 'calendar-days',
              type: '',
              route: '',
              timePeriod: timePeriod.value,
              description:
                'The total amount of estimates converted to booked projects by the selected companies. Includes pending, in-progress, and completed projects.',
              currentAmount: metrics.quotes_booked_count,
              previousAmount: metrics.quotes_booked_previous_count,
              biggerIsBetter: true
            },
            {
              title: 'Projects worked on',
              icon: 'helmet-safety',
              type: '',
              route: '',
              timePeriod: timePeriod.value,
              description:
                'The total amount of booked projects that are either in-progress or completed for the selected companies.',
              currentAmount: metrics.quotes_worked_count,
              previousAmount: metrics.quotes_worked_previous_count,
              biggerIsBetter: true
            }
          ]
        },
        {
          title: 'Leads & clients by company',
          description: 'The number of new leads and clients created by each company.',
          visible: true,
          size: 1,
          type: 'doughnut',
          options: {
            cutout: 80,
            plugins: {
              legend: {
                labels: {
                  ...defaultFontOptions,
                  usePointStyle: true,
                  boxWidth: 16,
                  boxHeight: 16
                }
              }
            }
          },
          data: {
            labels: Object.keys(metrics.new_clients_by_company),
            datasets: [
              {
                data: Object.values(metrics.new_clients_by_company),
                backgroundColor: gradients.blue.filter((_, i) => i % franchiseeCount === 0),
                borderColor: ['#f9f9f9'],
                borderWidth: 2
              }
            ]
          }
        },
        {
          title: 'Estimates sent vs projects booked by company',
          description: 'The number of estimates sent vs booked by each company.',
          visible: true,
          size: 2,
          type: 'bar',
          options: {
            indexAxis: 'y',
            scales: {
              y: {
                ...defaultAxisOptions,
                beginAtZero: true
              },
              x: {
                ...defaultAxisOptions
              }
            },
            plugins: {
              legend: {
                labels: {
                  ...defaultFontOptions,
                  usePointStyle: true,
                  boxWidth: 16,
                  boxHeight: 16
                }
              }
            }
          },
          data: {
            labels: Object.keys(metrics.quotes_sent_by_company),
            datasets: [
              {
                ...defaultBarChartDataset,
                label: 'Sent',
                data: Object.values(metrics.quotes_sent_by_company),
                backgroundColor: gradients.grey
              },
              {
                ...defaultBarChartDataset,
                label: 'Booked',
                data: Object.values(metrics.quotes_booked_by_company),
                backgroundColor: gradients.blue
              }
            ]
          }
        },
        {
          title: 'Projects worked on by company',
          description: 'The number of projects either in-progress or completed by each company.',
          visible: true,
          size: 1,
          type: 'doughnut',
          options: {
            cutout: 80,
            plugins: {
              legend: {
                labels: {
                  ...defaultFontOptions,
                  usePointStyle: true,
                  boxWidth: 16,
                  boxHeight: 16
                }
              }
            }
          },
          data: {
            labels: Object.keys(metrics.quotes_worked_by_company),
            datasets: [
              {
                data: Object.values(metrics.quotes_worked_by_company),
                backgroundColor: gradients.blue.filter((_, i) => i % franchiseeCount === 0),
                borderColor: ['#f9f9f9'],
                borderWidth: 2
              }
            ]
          }
        },
        {
          title: 'Finances',
          description:
            'Key financial performance indicators aggregated from the selected companies.',
          visible: true,
          size: 4,
          type: 'card',
          cards: [
            {
              title: 'Value estimated',
              icon: 'money-check-dollar-pen',
              type: 'currency',
              route: '',
              timePeriod: timePeriod.value,
              description:
                'The total value of estimates sent by the selected companies over this time period. Does not include taxes, making this the net total.',
              currentAmount: metrics.quotes_sent_price_net_sum,
              previousAmount: metrics.quotes_sent_price_net_previous_sum,
              biggerIsBetter: true
            },
            {
              title: 'Value booked',
              icon: 'comments-dollar',
              type: 'currency',
              route: '',
              timePeriod: timePeriod.value,
              description:
                'The total value of projects booked by the selected companies over this time period. Does not include taxes, making this the net total.',
              currentAmount: metrics.quotes_booked_price_net_sum,
              previousAmount: metrics.quotes_booked_price_net_previous_sum,
              biggerIsBetter: true
            },
            {
              title: 'Average project value',
              icon: 'box-dollar',
              type: 'currency',
              route: '',
              timePeriod: timePeriod.value,
              description:
                'The average value of projects booked by the selected companies within this time period. Does not include taxes, making this the net average.',
              currentAmount: metrics.quotes_booked_price_net_average,
              previousAmount: metrics.quotes_booked_price_net_previous_average,
              biggerIsBetter: true
            },
            {
              title: 'Average Profit',
              icon: 'circle-three-quarters-stroke',
              type: 'percent',
              route: '',
              timePeriod: timePeriod.value,
              description:
                'The average profit on items created by the selected companies within this time period. Includes estimates and booked projects.',
              currentAmount: c.markupToMargin(metrics.item_markup_average) * 100,
              previousAmount: c.markupToMargin(metrics.item_markup_previous_average) * 100,
              biggerIsBetter: true
            }
          ]
        },
        {
          title: 'Booked project value by company',
          description:
            'The net value of projects booked by each of the selected companies over time.',
          visible: true,
          size: 2,
          type: 'line',
          options: {
            scales: {
              y: {
                ...defaultAxisOptions,
                beginAtZero: true
              },
              x: {
                ...defaultAxisOptions
              }
            },
            plugins: {
              legend: {
                labels: {
                  ...defaultFontOptions,
                  usePointStyle: true,
                  boxWidth: 16,
                  boxHeight: 16
                }
              }
            }
          },
          data: {
            labels: getLabels(
              Object.keys(
                metrics.quotes_booked_price_net_by_company[
                  Object.keys(metrics.quotes_booked_price_net_by_company)[0]
                ]
              ),
              timePeriod.value
            ),
            datasets: Object.keys(metrics.quotes_booked_price_net_by_company).map(
              (company, index) => {
                return {
                  ...defaultLineChartDataset,
                  label: company,
                  data: Object.values(metrics.quotes_booked_price_net_by_company[company]),
                  pointBackgroundColor: gradients.blue.filter((_, i) => i % franchiseeCount === 0)[
                    index % franchiseeCount
                  ],
                  borderColor: gradients.blue.filter((_, i) => i % franchiseeCount === 0)[
                    index % franchiseeCount
                  ],
                  pointBorderColor: 'rgba(255,255,255,1)',
                  pointRadius: 5,
                  borderWidth: 2
                }
              }
            )
          }
        },
        {
          title: 'Sent estimate value by company',
          description:
            'The net value of estimates sent by each of the selected companies over time.',
          visible: true,
          size: 2,
          type: 'line',
          options: {
            scales: {
              y: {
                ...defaultAxisOptions,
                beginAtZero: true
              },
              x: {
                ...defaultAxisOptions
              }
            },
            plugins: {
              legend: {
                labels: {
                  ...defaultFontOptions,
                  usePointStyle: true,
                  boxWidth: 16,
                  boxHeight: 16
                }
              }
            }
          },
          data: {
            labels: getLabels(
              Object.keys(
                metrics.quotes_sent_price_net_by_company[
                  Object.keys(metrics.quotes_sent_price_net_by_company)[0]
                ]
              ),
              timePeriod.value
            ),
            datasets: Object.keys(metrics.quotes_sent_price_net_by_company).map(
              (company, index) => {
                return {
                  ...defaultLineChartDataset,
                  label: company,
                  data: Object.values(metrics.quotes_sent_price_net_by_company[company]),
                  pointBackgroundColor: gradients.blue.filter((_, i) => i % franchiseeCount === 0)[
                    index % franchiseeCount
                  ],
                  borderColor: gradients.blue.filter((_, i) => i % franchiseeCount === 0)[
                    index % franchiseeCount
                  ],
                  pointBorderColor: 'rgba(255,255,255,1)',
                  pointRadius: 5,
                  borderWidth: 2
                }
              }
            )
          }
        },
        {
          title: 'Total booked project value by company',
          description:
            'The total booked project value of each selected company over this time period.',
          visible: true,
          size: 2,
          type: 'bar',
          options: {
            indexAxis: 'y',
            scales: {
              y: {
                ...defaultAxisOptions,
                beginAtZero: true
              },
              x: {
                ...defaultAxisOptions
              }
            },
            plugins: {
              legend: {
                display: false
              }
            }
          },
          data: {
            labels: Object.keys(metrics.quotes_booked_price_net_sum_by_company),
            datasets: [
              {
                ...defaultBarChartDataset,
                data: Object.values(metrics.quotes_booked_price_net_sum_by_company),
                backgroundColor: gradients.blue.filter((_, i) => i % franchiseeCount === 0)
              }
            ]
          }
        },
        {
          title: 'Total sent estimate value',
          description:
            'The total sent estimate value of each selected company over this time period.',
          visible: true,
          size: 2,
          type: 'bar',
          options: {
            indexAxis: 'y',
            scales: {
              y: {
                ...defaultAxisOptions,
                beginAtZero: true
              },
              x: {
                ...defaultAxisOptions
              }
            },
            plugins: {
              legend: {
                display: false
              }
            }
          },
          data: {
            labels: Object.keys(metrics.quotes_sent_price_net_sum_by_company),
            datasets: [
              {
                ...defaultBarChartDataset,
                data: Object.values(metrics.quotes_sent_price_net_sum_by_company),
                backgroundColor: gradients.blue.filter((_, i) => i % franchiseeCount === 0)
              }
            ]
          }
        }
      ]
      return charts
    } catch (e) {
      console.error(e)
      return []
    }
  }

  return {
    getOverviewCharts,
    getFinanceCharts,
    getFranchiseCharts
  }
}

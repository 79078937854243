<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
    <a
      v-for="chart in metricCharts"
      :key="chart.title"
      class="relative flex flex-row justify-between items-center p-6 gap-4 rounded border cursor-pointer transition"
      :class="
        chart.visible
          ? 'border-cement-800 bg-cement-400 hover:bg-cement-300'
          : 'border-surface-300 hover:bg-surface-200/50'
      "
      @click="toggleChartVisibility(chart)"
      v-tooltip="chart.description"
    >
      <span class="font-medium text-lg">{{ chart.title }}</span>
      <icon v-if="chart.type === 'card'" :icon="['fal', 'credit-card-blank']" class="text-5xl" />
      <icon v-if="chart.type === 'bar'" :icon="['fal', 'chart-column']" class="text-5xl" />
      <icon v-if="chart.type === 'line'" :icon="['fal', 'chart-line']" class="text-5xl" />
      <icon v-if="chart.type === 'pie'" :icon="['fal', 'chart-pie']" class="text-5xl" />
      <icon v-if="chart.type === 'doughnut'" :icon="['fal', 'chart-pie']" class="text-5xl" />
    </a>
  </div>
</template>

<script setup>
import { useMetrics } from '@/components/metric/Metrics'

const { metricCharts, toggleChartVisibility } = useMetrics()
</script>

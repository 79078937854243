<template>
  <div class="flex flex-col gap-1 cursor-pointer col-span-1" :class="chartSize">
    <div class="flex flex-row items-center gap-3">
      <div
        class="h-fit w-fit flex justify-center items-center"
        v-tooltip="'Click and hold to drag'"
      >
        <icon
          :icon="['fas', 'grip-dots-vertical']"
          class="hidden md:inline text-lg text-surface-200 hover:text-surface-500 hover:bg-surface-200 rounded-sm p-1 transition"
        />
      </div>
      <span class="text-lg font-medium text-surface-700" v-tooltip="object.description">{{
        object.title
      }}</span>
    </div>

    <!-- Card container -->
    <template v-if="object.type === 'card'">
      <div class="gap-4 flex flex-row flex-wrap">
        <MetricsCard v-for="card in object.cards" :object="card" :key="card" />
      </div>
    </template>

    <!-- Chart.js chart -->
    <template v-else>
      <div
        class="flex flex-col justify-center items-center rounded-md p-4 h-full bg-surface-100/50"
      >
        <Chart
          v-if="hasData"
          :type="object.type"
          :data="object.data"
          :options="object.options"
          class="p-3 w-full h-full flex items-center justify-center"
          :class="object.type === 'doughnut' ? 'max-h-[275px]' : 'max-h-[700px]'"
        />
        <div v-else class="flex flex-col justify-center items-center text-center gap-8 h-full">
          <icon :icon="['fal', 'print-magnifying-glass']" class="text-7xl text-surface-300" />
          <p class="text-lg text-surface-300">Nothing here yet.</p>
          <p class="text-surface-300">
            Keep leveraging Bolster to gain greater insights into your business.
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import MetricsCard from '@/components/metric/charts/MetricsCard.vue'
import Chart from 'primevue/chart'
import { defineProps, computed } from 'vue'

const props = defineProps({
  object: {
    type: Object,
    default: null
  }
})

const hasData = computed(() => {
  if (props?.object?.type === 'card') return true
  if (props?.object?.data?.datasets) {
    return props.object.data.datasets.map((dataset) => !!dataset.data.length).includes(true)
  }
  return false
})

const chartSize = computed(() => {
  return props.object.size === 4
    ? 'lg:col-span-2 xl:col-span-3 2xl:col-span-4'
    : props.object.size === 3
      ? 'lg:col-span-2 xl:col-span-3'
      : props.object.size === 2
        ? 'lg:col-span-2'
        : ''
})
</script>

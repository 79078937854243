<template>
  <div
    v-if="object"
    @mouseover="hovering = true"
    @mouseout="hovering = false"
    class="flex flex-col border border-surface-200 rounded-md p-5 gap-y-4 grow min-w-[250px]"
  >
    <!-- Icon and view details -->
    <div class="flex flex-row justify-between">
      <icon :icon="['fal', object.icon]" class="text-3xl text-pitch-black" />
      <a
        v-show="!!!store.state.session.scope.franchisor && object.route && hovering"
        @click="onViewDetails"
        class="text-surface-500 hover:text-surface-800 transition cursor-pointer text-xs px-2 py-1 hover:bg-surface-200 flex justify-center items-center rounded-sm"
      >
        Details <font-awesome-icon icon="arrow-up-right" class="ml-1" />
      </a>
    </div>

    <!-- Main data -->
    <div class="animate-fadeIn">
      <!-- Current amount -->
      <div class="w-fit" v-tooltip="object.description" @click="onViewDetails">
        <p class="text-lg">{{ object.title }}</p>
        <p class="text-2xl font-medium text-pitch-black font-header tracking-wider">
          {{ displayValue(object.currentAmount, object.type) }}
        </p>
      </div>
      <div class="flex flex-row justify-between mt-4">
        <!-- Previous amount-->
        <div class="w-fit" v-tooltip="object.description">
          <p class="text-surface-500">Previous {{ object.timePeriod.toLowerCase() }}</p>
          <p class="text-md text-surface-500 font-header tracking-wider">
            {{ displayValue(object.previousAmount, object.type) }}
          </p>
        </div>
        <!-- Percent change -->
        <div
          v-show="percentChange"
          class="flex justify-center items-center self-end py-1 px-2 h-fit w-fit rounded-sm bg-blue-print/10 text-blue-print"
          v-tooltip="valueChange"
        >
          <span class="text-lg font-header tracking-wider">{{ percentChange }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { defineProps, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { formatCurrencySymbol } from '@/components/mixins/CurrencyFilter'

const store = useStore()

/**
 * Mapping of constants to display types.
 */
const Displays = {
  CURRENCY: 'currency',
  PERCENT: 'percent',
  TIMES: 'times'
}

const props = defineProps({
  object: {
    type: Object,
    default: null
  }
})

const router = useRouter()

/**
 * Used to indicate whether the current / previous trend is increasing or decreasing.
 */
const changeSign = computed(() => {
  if (!props.object.previousAmount) return ''
  if (props.object.currentAmount >= props.object.previousAmount) return '+'
  else return '-'
})

/**
 * Calculates the percent change between the passed amount and previousAmount values.
 * Returns a string of the format +12.34% or -12.34%.
 */
const percentChange = computed(() => {
  const percent =
    ((props.object.currentAmount - props.object.previousAmount) / props.object.previousAmount) * 100
  return changeSign.value + displayValue(Math.abs(percent)) + '%'
})

/**
 * Calculates the difference between the passed amount and previousAmount values.
 * Returns a string of the format +123 or -123.
 */
const valueChange = computed(() => {
  return (
    changeSign.value +
    displayValue(
      Math.abs(props.object.currentAmount - props.object.previousAmount),
      props.object.type
    )
  )
})

/**
 * Converts a raw Number type to a formatted display string.
 * '': 1,234,567,89
 * Currency: $ 1,234,567.89
 * Percent: 12.34%
 */
function displayValue(value, type = '') {
  if (value === null) value = 0
  const rounded = c.format(value, 'number', 0)
  switch (type) {
    case Displays.CURRENCY:
      return formatCurrencySymbol(value, 0)
    case Displays.PERCENT:
      return rounded + '%'
    case Displays.TIMES:
      return rounded + 'x'
  }
  return rounded
}

/**
 * On view details.
 * Navigate to page route when view details is pressed.
 */
function onViewDetails() {
  router.push({ name: props.object.route })
}

const hovering = ref(false)
</script>

import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useMetricsData } from '@/components/metric/MetricsData'
import moment from 'moment'

const { getOverviewCharts, getFinanceCharts, getFranchiseCharts } = useMetricsData()

const loaded = ref(false)
const metricCharts = ref()
const companyIds = ref([])
const timePeriod = ref('')
const currentTimeSteps = ref([])
const previousTimeSteps = ref([])

/**
 * Used as a key for the local storage object corrosponding to
 * the query defined by the company ids and time period passed.
 */
const queryKey = computed(() => {
  return `${companyIds.value.sort()}_${timePeriod.value}`
})

/**
 * Meta key
 * Used as a key for the local storage object corrosponding to
 * the chart meta data for the current set of company ids.
 */
const metaKey = computed(() => {
  return `${companyIds.value.sort()}`
})

export function useMetrics() {
  const store = useStore()
  let updateFailureCount = 0
  let metrics

  /**
   * Query the database for all relevant metrics.
   * Update charts and cards with retrieved data.
   */
  async function getMetrics() {
    loaded.value = false

    // Default company ids to just the current company
    if (!companyIds.value.length) {
      errorMessage('No company provided, could not retrieve metrics.')
      return
    }

    // If within the same day as the last query, retrieve locally stored metrics
    const storedMetrics = getStoredMetrics(queryKey.value)
    if (storedMetrics) {
      metrics = storedMetrics
    }

    // Otherwise query for fresh data
    else {
      try {
        const { payload } = await store.dispatch('ajax', {
          path: 'Metrics/getMetrics',
          data: {
            companyIds: companyIds.value,
            currentTimeSteps: currentTimeSteps.value,
            previousTimeSteps: previousTimeSteps.value
          }
        })
        metrics = payload
        metrics.time_queried = moment()
        setStoredMetrics(queryKey.value)
      } catch (e) {
        errorMessage(e)
        return
      }
    }

    updateCharts()
    loaded.value = true
  }

  /**
   * Limits metric queries to certain time ranges (where applicable).
   * @param {Object} range
   */
  function setTimeRange(range) {
    if (!range) return
    timePeriod.value = range.timePeriod
    currentTimeSteps.value = range.currentTimeSteps
    previousTimeSteps.value = range.previousTimeSteps
  }

  /**
   * For a start and end range, returns an array of every unix timestamp in that range.
   * @param {string} start
   * @param {string} end
   * @param {string} period
   * @returns {string[]}
   */
  function createTimeSteps(start, end, period) {
    let steps = []
    let step = 0
    let count = 0
    while (step <= Number(end)) {
      step = moment.unix(start).add(count, period).format('X')
      steps.push(step)
      count++
    }
    return steps
  }

  /**
   * @param {number[]} companies
   */
  function setCompanyIds(companies) {
    if (companies) companyIds.value = companies
  }

  function resetCompanyIds() {
    companyIds.value = []
  }

  /**
   * Given a key, set the object inside metrics local storage with this key to
   * the current value of the global variable "metrics".
   * @param {string} key
   */
  function setStoredMetrics(key) {
    let allMetrics = JSON.parse(localStorage.getItem('metrics'))
    if (!allMetrics) {
      allMetrics = { [key]: metrics }
    } else {
      allMetrics[key] = metrics
    }
    localStorage.setItem('metrics', JSON.stringify(allMetrics))
  }

  /**
   * Get stored metrics.
   * Tries to find the object inside metrics local storage with the given key.
   * Returns null if not found.
   * Returns null if the found metrics object was queried more than 1 day ago.
   * Otherwise returns the found metrics object.
   */

  /**
   * Tries to find the object inside metrics local storage with the given key.
   * @param {string} key
   * @returns {Object}
   */
  function getStoredMetrics(key) {
    if (!localStorage.getItem('metrics')) return null

    const storedMetrics = JSON.parse(localStorage.getItem('metrics'))[key]
    if (!storedMetrics) return null

    if (moment().diff(moment(storedMetrics.time_queried), 'days') > 0) return null
    else return storedMetrics
  }

  /**
   * Given a chart, toggles its value in local storage.
   * @param {Object} chart
   */
  function toggleChartVisibility(chart) {
    if (!localStorage.getItem('chartMeta')) return

    let chartMeta = JSON.parse(localStorage.getItem('chartMeta'))
    let companyChartMeta = chartMeta[metaKey.value]
    companyChartMeta[chart.title].visible = !chart.visible

    chartMeta[metaKey.value] = companyChartMeta
    localStorage.setItem('chartMeta', JSON.stringify(chartMeta))
    setChartMeta()
  }

  /**
   * Given a vuedraggable event, updates chart ordering to match the DOM.
   * This ordering is then saved to local storage to remain persistent.
   * @param {Object} event
   */
  function setChartOrdering(event) {
    if (!localStorage.getItem('chartMeta') || !event) return

    let chartMeta = JSON.parse(localStorage.getItem('chartMeta'))
    let companyChartMeta = chartMeta[metaKey.value]

    let index = 0
    event.from.children.forEach((element) => {
      companyChartMeta[element.id].order = index
      index++
    })

    chartMeta[metaKey.value] = companyChartMeta
    localStorage.setItem('chartMeta', JSON.stringify(chartMeta))
    setChartMeta()
  }

  /**
   * Sets chart visibility and ording based on local storage data.
   * If no local storage data is found, or it is outdated, sets defaults.
   */
  function setChartMeta() {
    // If no local storage for chart meta is found, create an empty object
    if (!localStorage.getItem('chartMeta')) {
      localStorage.setItem('chartMeta', JSON.stringify({}))
    }
    let chartMeta = JSON.parse(localStorage.getItem('chartMeta'))
    let companyChartMeta = chartMeta[metaKey.value]

    // If no local storage for this company is found, create a new empty object
    if (
      !companyChartMeta ||
      _.isEmpty(companyChartMeta) ||
      metricCharts.value.length !== Object.keys(companyChartMeta).length
    ) {
      companyChartMeta = {}
      let index = 0
      metricCharts.value.forEach((chart) => {
        companyChartMeta[chart.title] = {}
        companyChartMeta[chart.title].visible = chart.visible
        companyChartMeta[chart.title].order = index
        index++
      })
      chartMeta[metaKey.value] = companyChartMeta
      localStorage.setItem('chartMeta', JSON.stringify(chartMeta))
    }

    // Otherwise, update charts with stored meta data
    else {
      // Update visibilities
      Object.keys(companyChartMeta).forEach((key) => {
        const index = metricCharts.value.findIndex((chart) => chart.title === key)
        // If a chart has been renamed or added, reset to defaults
        if (!metricCharts.value[index]) {
          localStorage.removeItem('chartMeta')
          return setChartMeta()
        }
        metricCharts.value[index].visible = companyChartMeta[key].visible
        metricCharts.value[index].order = companyChartMeta[key].order
      })
      // Sort based on ordering
      metricCharts.value = metricCharts.value.sort((a, b) => a.order - b.order)
    }
  }

  /**
   * Update charts and cards with the data currently in metrics.
   * Different charts will load based on what the current context is.
   */
  function updateCharts() {
    try {
      metricCharts.value = []

      // Franchisor charts (only visible in franchisor scope)
      if (
        store.state.session.scope.franchisor &&
        companyIds.value.length > 1 &&
        store.state.session.user.user_is_admin
      ) {
        metricCharts.value = [...metricCharts.value, ...getFranchiseCharts(metrics, timePeriod)]
      } else {
        // Overview charts
        metricCharts.value = [...metricCharts.value, ...getOverviewCharts(metrics, timePeriod)]

        // Finance charts (only visible to company admins)
        if (store.state.session.user.user_is_admin) {
          metricCharts.value = [...metricCharts.value, ...getFinanceCharts(metrics, timePeriod)]
        }
      }
    } catch (e) {
      // If update was tried already, throw error
      if (updateFailureCount) {
        errorMessage(e)
        return
      }
      updateFailureCount++
      localStorage.removeItem('metrics')
      getMetrics()
    }

    setChartMeta()
  }

  /**
   * A generic error message for when a metrics process fails.
   */
  function errorMessage(e) {
    store.dispatch('alert', {
      error: true,
      message: 'Failed to fetch metrics. Please reload and try again.'
    })
    console.error(e)
  }

  /**
   * The selectable time ranges.
   */
  const timeRanges = ref([
    {
      text: 'This year',
      data: {
        timePeriod: 'year',
        currentTimeSteps: createTimeSteps(
          moment().subtract(11, 'months').startOf('month').format('X'),
          moment().endOf('month').format('X'),
          'months'
        ),
        previousTimeSteps: createTimeSteps(
          moment().subtract(23, 'months').startOf('month').format('X'),
          moment().subtract(12, 'months').endOf('month').format('X'),
          'months'
        )
      }
    },
    {
      text: 'This month',
      data: {
        timePeriod: 'month',
        currentTimeSteps: createTimeSteps(
          moment().subtract(30, 'days').startOf('day').format('X'),
          moment().endOf('day').format('X'),
          'days'
        ),
        previousTimeSteps: createTimeSteps(
          moment().subtract(60, 'days').startOf('day').format('X'),
          moment().subtract(30, 'days').startOf('day').format('X'),
          'days'
        )
      }
    },
    {
      text: 'This week',
      data: {
        timePeriod: 'week',
        currentTimeSteps: createTimeSteps(
          moment().subtract(6, 'days').startOf('day').format('X'),
          moment().endOf('day').format('X'),
          'days'
        ),
        previousTimeSteps: createTimeSteps(
          moment().subtract(14, 'days').startOf('day').format('X'),
          moment().subtract(7, 'days').startOf('day').format('X'),
          'days'
        )
      }
    }
  ])

  return {
    loaded,
    metricCharts,
    getMetrics,
    updateCharts,
    setTimeRange,
    timeRanges,
    setCompanyIds,
    resetCompanyIds,
    toggleChartVisibility,
    setChartOrdering
  }
}

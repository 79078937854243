<template>
  <div ref="root">
    <!-- Main button -->
    <a
      class="w-full md:w-64 relative flex flex-row justify-between border border-surface-900 py-2 px-3 gap-3 hover:bg-surface-100 transition rounded cursor-pointer"
      @click="() => (open = !open)"
    >
      <!-- Title and current selection -->
      <div class="flex flex-row items-center gap-2">
        <p class="text-surface-400 font-light">{{ title }}:</p>
        <p>
          {{ set[selected].text }}
        </p>
      </div>

      <div class="flex flex-row gap-3">
        <!-- Divider -->
        <div class="hidden md:block min-w-px min-h-full bg-surface-200" />

        <!-- Icon -->
        <div class="flex justify-center items-center">
          <Icon :icon="['far', icon]" class="px-1 py-0.5 text-lg" />
        </div>
      </div>
    </a>

    <!-- Dropdown -->
    <div
      v-show="open"
      class="absolute flex flex-col animate-fadeIn w-60 ml-2 z-50 bg-flame-white border-b border-l border-r border-surface-900 rounded-b"
    >
      <a
        v-for="(item, index) in set"
        :key="index"
        class="flex items-center p-2 gap-3 hover:text-blue-print cursor-pointer transition"
        @click.stop="input(index)"
      >
        <Icon :icon="index === selected ? 'circle-check' : 'circle'" />
        {{ item.text }}
      </a>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue'

const root = ref()
const selected = ref(1)
const open = ref(0)

const emit = defineEmits(['input'])

const props = defineProps({
  title: {
    type: String,
    default: 'Select'
  },
  set: {
    type: Array,
    default() {
      return []
    }
  },
  icon: {
    type: String,
    default: 'calendar'
  }
})

function input(index) {
  selected.value = index
  open.value = false
  emit('input', props.set[selected.value].data)
}

function handleClickOutside(event) {
  if (!root.value) return
  if (!root.value.contains(event.target) && open.value) {
    open.value = false
  }
}

onMounted(() => {
  document.getElementById('app').addEventListener('click', handleClickOutside)
})
</script>

export function useChartColors() {
  const baseColors = {
    blue: { h: 224, s: 1, l: 0.52 },
    orange: { h: 35, s: 0.84, l: 0.5 },
    green: { h: 119, s: 0.41, l: 0.5 },
    red: { h: 350, s: 0.68, l: 0.48 },
    cyan: { h: 169, s: 0.85, l: 0.4 },
    purple: { h: 259, s: 0.38, l: 0.6 },
    grey: { h: 30, s: 0.046, l: 0.608 },
    yellow: { h: 60, s: 1, l: 0.5 },
    black: { h: 359, s: 0.05, l: 0.11 } // Added yellow color
  }

  const gradients = {
    blue: makeGradient(baseColors.blue, 10, 1),
    orange: makeGradient(baseColors.orange, 10, 1),
    green: makeGradient(baseColors.green, 10, 1),
    red: makeGradient(baseColors.red, 10, 1),
    cyan: makeGradient(baseColors.cyan, 10, 1),
    purple: makeGradient(baseColors.purple, 10, 1),
    grey: makeGradient(baseColors.grey, 10, 1),
    yellow: makeGradient(baseColors.yellow, 10, 1),
    black: makeGradient(baseColors.black, 10, 1),
    blue_transparent: makeGradient(baseColors.blue, 10, 0.25),
    orange_transparent: makeGradient(baseColors.orange, 10, 0.25),
    green_transparent: makeGradient(baseColors.green, 10, 0.25),
    red_transparent: makeGradient(baseColors.red, 10, 0.25),
    cyan_transparent: makeGradient(baseColors.cyan, 10, 0.25),
    purple_transparent: makeGradient(baseColors.purple, 10, 0.25),
    grey_transparent: makeGradient(baseColors.grey, 10, 0.25),
    yellow_transparent: makeGradient(baseColors.yellow, 10, 0.25),
    black_transparent: makeGradient(baseColors.black, 10, 0.25)
  }

  /**
   * @param {Object} color => object with the following:
   *   h => Hue [0, 360]
   *   s => Saturation [0, 1]
   *   l => Lightness [0, 1]
   * @param {number} shades => the number of discrete shades.
   * @param {number} opacity => the alpha value of the color.
   * @returns {string[]}
   */
  function makeGradient(color, shades = 1, opacity = 1) {
    let gradient = []
    let count = 0
    let shade = 1

    while (count < shades) {
      const rgb = hslToRgb(color.h, color.s, color.l * shade)
      gradient.push(`rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`)
      shade = shade + 1 / shades
      count++
    }
    return gradient
  }

  /**
   * @param {string[]} gradient => an array of 'rgba(r,g,b,a)' strings.
   * @param {number} repeat => number of repetitions.
   * @returns {string[]}
   */
  function repeatGradient(gradient, repeat) {
    let count = 0
    let repeatedGradient = _.imm(gradient)
    while (count < repeat) {
      repeatedGradient.pop()
      if (count % 2 === 0) {
        repeatedGradient = [...repeatedGradient, ...gradient.toReversed()]
      } else {
        repeatedGradient = [...repeatedGradient, ...gradient]
      }
      count++
    }
    return repeatedGradient
  }

  /**
   * @param {number} h => [0, 360]
   * @param {number} s => [0, 1]
   * @param {number} l => [0, 1]
   * @returns {number[]}
   */
  function hslToRgb(h, s, l) {
    let a = s * Math.min(l, 1 - l)
    let f = (n, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return [f(0) * 255, f(8) * 255, f(4) * 255]
  }

  return {
    baseColors,
    makeGradient,
    repeatGradient,
    gradients
  }
}
